import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

function AboutUs() {
  return (
    <Layout>
      <SEO
        keywords={[`aluminum profile`, `cnc`, `automation`, `window`]}
        title="About Us"
      />

      <div className="overflow-hidden bg-white">
        <div className="relative px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="absolute top-0 bottom-0 hidden w-screen lg:block bg-gray-50 left-3/4"></div>
          <div className="mx-auto text-base max-w-prose lg:max-w-none">
            <p className="text-base font-semibold leading-6 tracking-wide text-orange-600 uppercase">
              Our Founder
            </p>
            <h1 className="mt-2 mb-8 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              Meet Jan
            </h1>
          </div>
          <div className="lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative mb-8 lg:mb-0 lg:row-start-1 lg:col-start-2">
              <svg
                className="absolute top-0 right-0 hidden -mt-20 -mr-20 lg:block"
                width="404"
                height="384"
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-green-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="384"
                  fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
                />
              </svg>
              <div className="relative mx-auto text-base max-w-prose lg:max-w-none">
                <figure>
                  <div className="relative pb-7/12 lg:pb-0">
                    <img
                      src="https://res.cloudinary.com/dhcx3vzmg/image/upload/e_improve,w_1200/v1603890388/olermd2ukerqjzzgfxzk.jpg"
                      alt=""
                      width="1184"
                      height="1376"
                      className="absolute inset-0 object-cover object-center w-full h-full max-w-sm rounded-lg shadow-lg lg:static lg:h-auto"
                    ></img>
                  </div>
                </figure>
              </div>
            </div>
            <div>
              <div className="mx-auto text-base text-lg leading-7 prose text-gray-500 max-w-prose">
                <p>
                  Jan has worked with CNC machines since they got introduced to 
                  the aluminum, plastic and steel profile industry. 
                  He installed and operated the machines all over the globe.
                </p>
                <p>
                  These experiences led Jan to the idea to develop a brand new 
                  software platform, easy to use yet powerful and above all, 
                  compatible with all brands and types of machines. Uni_Link was 
                  born.
                </p>
                <p>
                  Uni_Link included a strong optimization algorithm and ways to 
                  connect with all kinds of calculation and construction software, 
                  so that the profile fabrication process is to be the least of your 
                  worries.
                </p>
                <p>
                  Uni_Link is a tight knit company with offices in Belgium, The 
                  Netherlands, Poland and the USA and is currently starting up offices 
                  in Turkey and Asia. The core business of Uni_Link is to become an 
                  integral partner for companies that maintain our services. 
                  Uni_Link is accessible and delivers prompt and straightforward technical 
                  support.    
                 </p>
                <footer className="mt-8">
                  <div className="md:flex">
                    <div className="md:flex-shrink-0">
                      <img
                        className="w-10 h-10 mx-auto rounded-full"
                        src="https://res.cloudinary.com/dhcx3vzmg/image/upload/c_thumb,w_200,h_200,g_face/v1603890388/olermd2ukerqjzzgfxzk.jpg"
                        alt=""
                      ></img>
                    </div>
                    <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                      <div className="text-base font-medium leading-6 text-gray-900">
                        Jan Vandyck
                      </div>

                      <svg
                        className="hidden w-5 h-5 mx-1 text-green-600 md:block"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M11 0h3L9 20H6l5-20z" />
                      </svg>

                      <div className="text-base font-medium leading-6 text-gray-500">
                        President, Uni_Link Company Group
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutUs
